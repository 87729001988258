import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import styled from "styled-components";

const NotFoundPage = ({ data }) => {
  const {
    strapiGlobal,
    site: {
      siteMetadata: {
        languages: { locales, defaultLocale },
      },
    },
  } = data

  return (
    <>
      <Seo global={strapiGlobal} />
      <Layout
        pageContext={{
          locale: defaultLocale,
          locales,
          defaultLocale,
          slug: "404",
        }}
        global={strapiGlobal}
      >
        <NotFoundContainer>
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </NotFoundContainer>

      </Layout>
    </>
  )
}

const NotFoundContainer = styled.div`
  text-align: center;
  padding: 15rem 1rem;
  color: #1AEFBC;
  min-height: calc(100vh - 15rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export default NotFoundPage

export const query = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
      }
    }
    strapiGlobal {
      ...GlobalData
    }
  }
`
